import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";
// images
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

import Chimney1 from '../../Images/PhotoImg1.jpg'
import Chimney2 from '../../Images/PhotoImg2.jpg'
import Chimney3 from '../../Images/PhotoImg3.jpg'
import Chimney4 from '../../Images/PhotoImg4.jpg'
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '../../Components/ServicesPage/AreaData'


export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Moreno Valley, CA Air Duct Cleaning | Expert Air Duct Cleaning, Inspection & Repair Services | 951-517-3517</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Moreno Valley Air Duct Cleaning | Expert Air Duct Cleaning, Inspection & Repair Services | 951-517-3517" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Moreno Valley Air Duct Cleaning | Expert Air Duct Cleaning, Inspection & Repair Services | 951-517-3517" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.morenovalleyairduct.org/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Moreno Valley Air Duct Cleaning provides top-quality Air Duct Cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Air Duct service needs." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Moreno Valley Air Duct Cleaning provides top-quality Air Duct Cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Air Duct service needs." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Moreno Valley, CA Air Duct Cleaning & Cleaning" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Moreno Valley, CA Air Duct Cleaning & Cleaning" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rain Cap Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Air Duct Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>The AIR DUCT Experts You've Trusted for Over 50 Years in Moreno Valley</h1>
                    <h2>Servicing Moreno Valley And Surrounding Areas - Air Duct Cleaning Near Me In Moreno Valley, California</h2>
                    <a href="tel:951-517-3517">Call Us Now!</a>
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning Near Me In Moreno Valley, California" /></a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt="Air Duct Cleaning In Moreno Valley, CA | Air Duct Maintenance In Moreno Valley, CA | AC Duct Cleaning In Moreno Valley, CA | Dryer Vent Cleaning In Moreno Valley, CA" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>OUR AIR DUCT SERVICES</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="/service/Air-Duct-Cleaning" className="MainBanner2TopBoxBox">
                            <img src={appcalendar} alt="Air Duct Cleaning" />
                            <h3>Air Duct Cleaning</h3>
                            <h2>Thorough cleaning of air ducts to improve indoor air quality, remove contaminants, and enhance HVAC system efficiency.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Air-Duct-Maintenance" className="MainBanner2TopBoxBox">
                            <img src={appdropshipping} alt="Air Duct Maintenance" />
                            <h3>Air Duct Maintenance</h3>
                            <h2>Regular maintenance of air ducts ensures optimal airflow, prevents blockages, and extends the life of your HVAC system.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/AC-Duct-Cleaning" className="MainBanner2TopBoxBox">
                            <img src={appsearch} alt="AC Duct Cleaning" />
                            <h3>AC Duct Cleaning</h3>
                            <h2>Specialized cleaning of AC ducts to eliminate dust, allergens, and debris, ensuring cool, clean air circulation.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Dryer-Vent-Cleaning" className="MainBanner2TopBoxBox">
                            <img src={appwallet} alt="Dryer Vent Cleaning" />
                            <h3>Dryer Vent Cleaning</h3>
                            <h2>Professional cleaning of dryer vents to reduce fire hazards, improve drying efficiency, and prolong the lifespan of your dryer.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                    </div>
                </div>
            </div>
            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={Chimney3} alt="Air Duct Cleaning In Moreno Valley, CA | Air Duct Maintenance In Moreno Valley, CA | AC Duct Cleaning In Moreno Valley, CA | Dryer Vent Cleaning In Moreno Valley, CA" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <h4>LEAVE AIR-DUCT CARE TO THE PROS</h4>
                        <h5>BECAUSE WITH Moreno Valley Air Duct Cleaning, YOU GET MORE THAN JUST A SERVICE—YOU GET PEACE OF MIND.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>REPUTATION</h2>
                            <h3>Trusted Air Duct experts since 1975 In Moreno Valley, California. Delivering exceptional service and craftsmanship with decades of proven expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TRANSPARENCY</h2>
                            <h3>No hidden fees—our honest pricing covers Air Duct services any time, including holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>EFFICIENCY</h2>
                            <h3>Prompt same-day and emergency Air Duct services In Moreno Valley, California, available 365 days a year to meet your needs.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>QUALITY</h2>
                            <h3>Comprehensive Air Duct care, from cleaning to repair, using advanced techniques and the latest equipment.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <div className="MainBanner2TopTopBtn">
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning In Moreno Valley, CA | Air Duct Maintenance In Moreno Valley, CA | AC Duct Cleaning In Moreno Valley, CA | Dryer Vent Cleaning In Moreno Valley, CA" /></a>
                    <a href="tel:951-517-3517">CALL NOW 951-517-3517</a>
                </div>
            </div>
            <div className="MainBanner3">
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Cleaning Moreno Valley, CA</h2>
                            <h3>
                                Exceptional Air Duct Cleaning Services in Moreno Valley, CA
                                <br />
                                <br />
                                Air duct cleaning is crucial for maintaining healthy indoor air quality and the efficiency of your HVAC system. Over time, dust, allergens, and debris accumulate in your air ducts, reducing airflow and potentially leading to health issues. Our professional air duct cleaning services thoroughly remove these contaminants, enhancing the performance of your HVAC system and ensuring cleaner air in your home. Trust our experienced technicians to provide a comprehensive cleaning that promotes a healthier living environment.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Air-Duct-Cleaning">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney1} alt="Air Duct Cleaning Moreno Valley, CA" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">

                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Maintenance Moreno Valley, CA</h2>
                            <h3>
                                Exceptional Air Duct Maintenance Services in Moreno Valley, CA
                                <br />
                                <br />
                                Regular air duct maintenance is essential for the longevity and efficiency of your HVAC system. With consistent use, air ducts can develop blockages, leaks, and other issues that compromise airflow and system performance. Our air duct maintenance services include inspection, cleaning, and minor repairs to ensure optimal airflow and system reliability. By scheduling regular maintenance, you prevent costly repairs and extend the life of your HVAC system, while also improving indoor air quality and energy efficiency.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Air-Duct-Maintenance">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney2} alt="Air Duct Inspection Moreno Valley, CA" />
                        </div>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>AC Duct Cleaning Moreno Valley, CA</h2>
                            <h3>
                                Professional AC Duct Cleaning Services in Moreno Valley, CA
                                <br />
                                <br />
                                AC duct cleaning is vital for maintaining a cool and comfortable indoor environment, especially during the hot summer months. Over time, dust, mold, and allergens can accumulate in your AC ducts, reducing the efficiency of your cooling system and affecting air quality. Our specialized AC duct cleaning services remove these contaminants, ensuring that your air conditioning system operates at peak performance. Enjoy cleaner, fresher air and improved energy efficiency with our professional AC duct cleaning service.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/AC-Duct-Cleaning">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney3} alt="Air Duct Repair Moreno Valley, CA" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Dryer Vent Cleaning Moreno Valley, CA</h2>
                            <h3>
                                Top-Notch Dryer Vent Cleaning Services in Moreno Valley, CA
                                <br />
                                <br />
                                Dryer vent cleaning is a critical service that helps prevent potential fire hazards and ensures the efficiency of your dryer. Lint and debris can build up in dryer vents over time, leading to blockages that increase drying time and energy consumption. Our professional dryer vent cleaning services remove this buildup, reducing the risk of fire and improving dryer performance. Regular cleaning also extends the lifespan of your dryer, saving you money on energy bills and appliance replacement costs.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Dryer-Vent-Cleaning">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney4} alt="Air Duct Maintenance Moreno Valley, CA" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with Air Duct Cleaning Services in Moreno Valley ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Reach Out to Us</h3>
                            <h4>Contact our expert team by phone or visit our website to schedule your Moreno Valley Air Duct Cleaning service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide Your Details</h3>
                            <h4>Share your address and any specific details about your home that could impact the service, ensuring our team is well-prepared.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Help Us Understand Your Situation</h3>
                            <h4>Send us photos of your Air Duct to give us a clear view of its current condition, allowing us to provide an accurate service estimate.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Schedule Your Service</h3>
                            <h4>Discuss the urgency of your needs, and we’ll arrange a convenient date and time for our team to visit.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Receive a Customized Quote</h3>
                            <h4>Our local service provider will assess your requirements and provide a quote based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href="tel:951-517-3517">CALL NOW 951-517-3517</a>
                    <a href="/book">SCHEDULE ONLINE</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={Chimney2} alt="Air Duct Cleaning Near Me In Moreno Valley, California" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <div className="MainBanner6Left">
                    <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                    <h3>Explore Our FAQs for Your Air Duct Needs or Watch Our Latest Videos.</h3>
                    <a href="/faq">READ OUR Air Duct FAQS</a>
                </div>
                <div className="MainBanner6Right">
                    <img src={bgnobgs} alt="Air Duct Cleaning In Moreno Valley, CA | Air Duct Maintenance In Moreno Valley, CA | AC Duct Cleaning In Moreno Valley, CA | Dryer Vent Cleaning In Moreno Valley, CA" />
                </div>
            </div>
            <div className="MainBanner7">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <div className="MainBanner7Maein">
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>"Moreno Valley Air Duct Cleaning exceeded my expectations! Their team was punctual, professional, and thorough. They removed years of dust from my ducts, significantly improving my home's air quality. I highly recommend them for anyone needing reliable, top-notch service. Truly a great experience!"</h2>
                        <h4>Michael M.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>"I’m thoroughly impressed with Moreno Valley Air Duct Cleaning. The technicians were friendly, knowledgeable, and very detail-oriented. They did an excellent job cleaning my ducts, and I noticed an immediate improvement in air quality. Their commitment to customer satisfaction is evident. Highly recommend their services!"</h2>
                        <h4>Sarah J.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>"Moreno Valley Air Duct Cleaning delivered exceptional service! They were prompt, efficient, and left my air ducts spotless. I appreciated their attention to detail and use of high-quality equipment. My home feels fresher, and I’m completely satisfied with their work. I’ll definitely use them again!"</h2>
                        <h4>Emily R.</h4>
                    </div>
                </div>
                <a href="/reviews">MORE FROM OUR CUSTOMERS &gt;</a>
            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Right">
                    <h1>OUR Air Duct SERVICES</h1>
                    <h2>At Moreno Valley Air Duct Cleaning, we provide a full range of Air Duct services, including cleaning, inspection, repair, and maintenance. Our certified technicians are dedicated to ensuring your Air Duct is safe, efficient, and in optimal condition. We use only high-quality materials and cutting-edge equipment to deliver outstanding results, enhancing both the safety and comfort of your home while giving you peace of mind.</h2>
                    <div className="MainBanner4RightBenefits">          {AreaData.map((A, index) =>
                        <a href={A.Path} className="Header1BoxMenuBox">
                            {A.TitleShortVery}
                        </a>
                    )}
                    </div>
                </div>
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

